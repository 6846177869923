//css
import styles from '../css/app.pcss';

import 'promise-polyfill/src/polyfill';
import Accordion from "accordion/src/accordion.mjs";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";

//IMGS
import Arrow from "../img/arrow.svg";
import ArrowSelect from "../img/arrow-select.svg";
import CartIcon from "../img/cart-icon.svg";
import AgendaIcon from "../img/agenda-icon.svg";
import LocationIcon from "../img/location-icon.svg";
import Enveloppe from "../img/enveloppe.svg";
import HandCrafted from "../img/handcrafted.svg";
import LogoLarge from "../img/logo-large.svg";
import LogoBig from "../img/logo-big.svg";
import LogoSmall from "../img/logo-small.svg";
import UserIcon from "../img/user-icon.png";
import TimeIcon from "../img/time-icon.svg";


// App main
let SvgInline;

const main = async () => {
    //await import( /* webpackChunkName: "object-fit-images" */ 'object-fit-images');
    SvgInline = await import( /* webpackChunkName: "inline-svg" */ 'inline-svg');
    
    // Async load the vue module
    const Vue = await import( /* webpackChunkName: "vue" */ 'vue');
    const axios = await import( /* webpackChunkName: "axios" */ 'axios');
    const VueAxios = await import( /* webpackChunkName: "vue-axios" */ 'vue-axios');
    const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');
    
    Vue.default.use(VueAxios, axios, VueCookies);
    
    new Vue.default({
        el: "#cookie-consent",
        
        data: {
            confirmedTimedOut: false,
            confirmed: false
        },
        
        methods: {
           confirmCookieConsent(){
               let vm = this;
               
               setTimeout(function() {
                   vm.confirmedTimedOut = true;
               }, 600);
               VueCookies.set('cookieConsent', 'confirmed', { expires: 365 });
               
               this.confirmed = true;
           }
        }
    });
    
    new Vue.default({
        el: "#mobile-nav",
        
        data: {
            mobileNavOpen: false,
            mobileSubNavCollectionsOpen: null
        },
        
        methods: {
            toggleMobileNav() {
                return this.mobileNavOpen = !this.mobileNavOpen;
            },

            toggleMobileSubNavCollections(id) {
                this.mobileSubNavCollectionsOpen = this.mobileSubNavCollectionsOpen === id ? 'null' : id;
            }
        },
        
        mounted() {}
    });
    
    new Vue.default({
        el: "#product-page",
        delimiters: ['${', '}'],
    
        components: {
            'vue-variant-select': () => import( /* webpackChunkName: "agenda" */ '../vue/VariantSelect.vue')
        },
    
        data: {
            cartModalOpen: false,
            currentVariant: ''
        },
        
        methods: {
            addToCart() {
                return this.cartModalOpen = true;
            },
    
            closeCartModal() {
                return this.cartModalOpen = false;
            },
    
            onSelectUpdate(variant) {
                this.currentVariant = variant;
            }
        }
    });
    
    new Vue.default({
        el: "#contact-form",
        
        data: {
            formStatus: '',
            fromName: '',
            fromEmail: '',
            message: '',
            privacyPolicy: false
        },
        
        methods: {
            validateForm(){
                let validationPassed = false;
                
                if(this.fromName === '' || this.fromEmail === '' || this.message === ''){
                    this.formStatus = 'incomplete';
                } else if(!this.privacyPolicy){
                    this.formStatus = 'privacy';
                } else {
                    validationPassed = true;
                }
                
                return validationPassed;
            },
            
            sendForm(){
                let vm = this;
                
                if(this.validateForm()){
                    let contactForm = document.getElementById('contactform');
                    let data = new FormData(contactForm);
    
                    axios.post('.', data)
                        .then(function (response) {
                            vm.formStatus = 'success';
                        })
                        .catch(function (error) {
                            vm.formStatus = 'error';
                        });
                }
            }
        }
    });
    
    new Vue.default({
        el: "#notification-pop-up",
        
        data: {
            showPopUpNotificationBar: true,
            bodyScrollEl: null
        },
        
        methods: {
            closePopUpNotification(slug) {
                VueCookies.set(`popUpNotification-${slug}`, "hidden", 0);
                this.showPopUpNotificationBar = false;
                if (this.bodyScrollEl) enableBodyScroll(this.bodyScrollEl);
            }
        },
        
        mounted() {
            this.bodyScrollEl = document.getElementById("notification-pop-up");
            this.showPopUpNotificationBar && this.bodyScrollEl
            ? disableBodyScroll(this.bodyScrollEl)
            : enableBodyScroll(this.bodyScrollEl);
        }
    });

    new Vue.default({
        el: "#masonry",
    
        data() {
            return {
                msnry: null
            }
        },
    
        created() {
            window.addEventListener("resize", this.masonryLayout);
        },
    
        beforeDestroy() {
            window.removeEventListener("resize", this.masonryLayout);
        },
        
        mounted() {
            const elem = document.querySelector('.masonry-grid');
            
            if (elem) {
                // Init Masonry
                this.msnry = new Masonry(elem, {
                    itemSelector: '.masonry-item',
                    columnWidth: '.masonry-sizer',
                    percentPosition: true,
                    stamp: '.stamp',
                    transitionDuration: '0',
                    stagger: 0
                });
    
                // If all images are loaded trigger layout
                imagesLoaded(elem, () => {
                    this.msnry.layout();
                })
    
                // Check which items are in the first column and add a class
                this.msnry.on('layoutComplete', (laidOutItems) => {
                    laidOutItems.forEach(item => {
                        item.element.classList.remove("is-left")
                        if (item.position.x === 0) item.element.classList.add("is-left")
                    })
                    
                    // Unhide the grid with a transition
                    elem.classList.add("loaded")
    
                    // Position the background to the top of the second image
                    this.positionMasonryBg();
                })
            }
        },
        
        methods: {
            masonryLayout() {
                if (this.msnry) {
                    this.msnry.layout();
                    this.positionMasonryBg();
                }
            },
            
            positionMasonryBg() {
                // Position background
                const masnryIntro = document.querySelector('.masonry-intro');
                const msnryBg = document.querySelector('#masonry-bg');
                if (masnryIntro && msnryBg) {
                    const bgStart = masnryIntro.offsetHeight + parseInt(getComputedStyle(masnryIntro).marginTop) + parseInt(getComputedStyle(masnryIntro).marginBottom);
                    msnryBg.style.paddingTop = bgStart + "px";
                }
            }
        }
    });

    //IMGS
    var arrowContainers = document.getElementsByClassName('img-arrow');
    [].forEach.call(arrowContainers, function (el) {
        el.src = Arrow;
    });
    var arrowSelectContainers = document.getElementsByClassName('img-arrow-select');
    [].forEach.call(arrowSelectContainers, function (el) {
        el.src = ArrowSelect;
    });
    var carticonContainers = document.getElementsByClassName('img-carticon');
    [].forEach.call(carticonContainers, function (el) {
        el.src = CartIcon;
    });
    var agendaiconContainers = document.getElementsByClassName('img-agendaicon');
    [].forEach.call(agendaiconContainers, function (el) {
        el.src = AgendaIcon;
    });
    var locationiconContainers = document.getElementsByClassName('img-locationicon');
    [].forEach.call(locationiconContainers, function (el) {
        el.src = LocationIcon;
    });
    var enveloppeContainers = document.getElementsByClassName('img-enveloppe');
    [].forEach.call(enveloppeContainers, function (el) {
        el.src = Enveloppe;
    });

    var handCraftedContainers = document.getElementsByClassName('img-handcrafted');
    [].forEach.call(handCraftedContainers, function (el) {
        el.src = HandCrafted;
    });
    var logoLargeContainers = document.getElementsByClassName('img-logolarge');
    [].forEach.call(logoLargeContainers, function (el) {
        el.src = LogoLarge;
    });
    var logoBigContainers = document.getElementsByClassName('img-logobig');
    [].forEach.call(logoBigContainers, function (el) {
        el.src = LogoBig;
    });
    var logosmallContainers = document.getElementsByClassName('img-logosmall');
    [].forEach.call(logosmallContainers, function (el) {
        el.src = LogoSmall;
    });
    var userIconContainers = document.getElementsByClassName('img-usericon');
    [].forEach.call(userIconContainers, function (el) {
        el.src = UserIcon;
    });
    var timeIconContainers = document.getElementsByClassName('img-timeicon');
    [].forEach.call(timeIconContainers, function (el) {
        el.src = UserIcon;
    });
};
// Execute async function
main().then((value) => {
    SvgInline.init({
        svgSelector: 'img.svg-inline', // the class attached to all images that should be inlined
    }, function () {
        //console.log('All SVGs inlined');
    });
    
    // IE Object-fit ployfill
    //objectFitImages();
    
    // Accordion (eg. Agenda)
    for (const el of document.querySelectorAll(".accordion")) {
       /* setTimeout because height is not calculated on time on Safari */
        setTimeout(function(){
            new Accordion(el, {
                modal: true,
                useBorders: true
            });
        }, 200);
        
    }
});
